<template>
    <el-input v-model="val" @blur="onBlur"></el-input>
</template>
<script>
	export default {
        props:['someProp'],
		data() {
			return {
                val: ''
			}
		},
        created(){
            this.val = this.someProp.column_label;
        },
		methods:{

            //修改表头
            onBlur() {
                const {columnData,column_index} =this.someProp;
               columnData.forEach((item, i) => {
                    if (column_index === i) {
                        item.label = this.val;
                    }
                });
            },
		}
	}
</script>